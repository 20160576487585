import React, { FC } from 'react';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Rhombus } from 'components/atoms/Geometric/Rhombus/Rhombus';

import useMedia from 'hooks/useMedia';

import { Inputs } from './Inputs/Inputs';
import {
  StyledContactFormContainer,
  StyledContactForm,
  StyledInputColumn,
  StyledTextWrapper,
  StyledTextColumn,
  StyledBackground,
  StyledContainer
} from './ContactForm.styles';

export type ContactFormProps = {
  title: string;
  description: string;
};

export const ContactForm: FC<ContactFormProps> = ({ title, description }) => {
  const { isMobile } = useMedia();

  return (
    <StyledContainer>
      {!isMobile && <StyledBackground />}
      <StyledContactFormContainer>
        <StyledTextColumn>
          {!isMobile && (
            <>
              <Rhombus width="29px" top="5px" left="-40px" variant="purple" moveDistance="200px" moveDuration="9s" />
              <Rhombus
                width="13px"
                top="-40px"
                left="35px"
                variant="purple"
                moveDistance="150px"
                moveDuration="7.5s"
                moveDelay="1.2s"
              />
              <Rhombus
                width="13px"
                top="420px"
                left="80px"
                variant="purple"
                moveDistance="170px"
                moveDuration="8s"
                moveDelay="0.75s"
              />
            </>
          )}
          <Container>
            <Typography variant="h1" renderAs="h2">
              {title}
            </Typography>
            <StyledTextWrapper>
              <Typography variant="body1">{description}</Typography>
            </StyledTextWrapper>
          </Container>
        </StyledTextColumn>
        <StyledInputColumn>
          <StyledContactForm>
            <Container>
              <Inputs variant="onViolet" />
            </Container>
          </StyledContactForm>
        </StyledInputColumn>
      </StyledContactFormContainer>
    </StyledContainer>
  );
};
